















import { Component, Vue } from 'vue-property-decorator';
import * as api from '@/store/api';
import router from '@/router';
@Component
export default class OfflinePage extends Vue {
  async created() {
    if (process.env.VUE_APP_OFFLINE === 'false') {
      var online = await api.testOnline();
      if (online) {
        router.push('/');
      }
    }
  }
}
